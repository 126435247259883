import http from '@/axios/index.js'

/**
 * 用户登录
 * @param { Object } data
 * @param { string } data.username 用户名
 * @param { string } data.password 用户密码
*/
export function userLogin (data) {
  return http({
    url: 'open/user/login',
    method: 'post',
    data
  })
}

/**
 * 用户注册
 * @param { Object } data
 * @param { string } data.username 用户名
 * @param { string } data.password 用户密码
*/
export function userRegister (data) {
  return http({
    url: 'open/user/register',
    method: 'post',
    data
  })
}

/**
 * 获取用户登录信息
*/
export function getUserInfo () {
  return http({
    url: 'api/user/info',
    method: 'post',
  })
}

/**
 * 用户退出登录
*/
export function userOutLogin () {
  return http({
    url: 'api/user/logout',
    method: 'post',
  })
}

/**
 * 店铺入住
 * @param { object } data
*/
export function addShop (data) {
  return http({
    url: 'api/addShop',
    method: 'post',
    data
  })
}
/**
 * 重新提交入驻
 * @param { object } data
*/
export function updateShopApi (data) {
  return http({
    url: 'api/updateShop',
    method: 'post',
    data
  })
}

/**
 * 获取我的订单
 * @param { object } data
*/
export function getOrderList () {
  return http({
    url: 'api/getOrderList',
    method: 'get'
  })
}

/**
 * 创建订单
 * @param { object } params
*/
export function addOrder (data) {
  return http({
    url: 'api/addOrder',
    method: 'post',
    data
  })
}

/**
 * 创建订单
 * @param { object } params
*/
export function getOtherStatusApi (params) {
  return http({
    url: 'api/queryOrderState',
    method: 'get',
    params
  })
}
/**
 * 取消订单
 * @param { object } params
 * @param { string } params.orderNo
*/
export function orderCancel(data) {
  return http({
    url: 'api/orderCancel',
    method: 'post',
    params: data
  })
}
/**
 * 申请退款
 * @param { object } params
 * @param { string } params.orderNo
*/
export function orderRefundApi(data) {
  return http({
    url: 'api/orderRefund',
    method: 'post',
    params: data
  })
}
/**
 * 获取用户入驻列表
*/
export function getMyShopListApi() {
  return http({
    url: 'api/getMyShopList',
    method: 'post'
  })
}
/**
 * 订单支付
 * @params {Object} params
 * @param { string } params.orderNo
*/
export function orderPayApi(params) {
  return http({
    url: 'api/orderPay',
    method: 'post',
    params
  })
}

/**
 * 获取分类
*/
export function getCategoryList() {
  return http({
    url: '/open/getCategoryList',
    method: 'get'
  })
}

