import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo } from '@/axios/userBasics/index.js';
import { getAdminInfo } from '@/axios/adminBasics/index.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userInfo: {},
    routerHistory: [],
    userToken: '',
    adminToken: '',
    adminInfo: {},
    rouseLoginInfo: {
      show: false,
      type: 'login',
      userType: 'admin',
    }
  },
  mutations: {
    setRouseLoginInfo (state, data) {
      state.rouseLoginInfo = { ...state.rouseLoginInfo, ...data }
    },
    // 设置adminToken
    setAdminToken (state, token) {
      state.adminToken = token
      localStorage.setItem('adminToken', token)
    },
    
    // 设置用户信息
    setAdminInfo (state, adminInfo) {
      state.adminInfo = {...state.adminInfo, ...adminInfo }
    },

    // 刷新页面重新获取用户信息
    async uploadAdminInfo (state) {
      if(state.adminInfo.userName) {return}
      let adminToken = localStorage.getItem('adminToken')
      if (adminToken) {
        state.adminToken = adminToken
        // 需要再次获取用户基本信息，做常态化登录，避免刷新时丢失登录信息
        try { 
          let res = await getAdminInfo()
          state.adminInfo = { ...state.adminInfo, ...res.data }
        } catch (err) {
          console.log(err)
          state.adminInfo = {}
          state.adminToken = ''
          localStorage.removeItem('adminToken')
        }
      }
    },

    // 设置用户token
    setUserToken (state, token) {
      state.userToken = token
      localStorage.setItem('userToken', token)
    },

    // 设置用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = { ...state.userInfo, ...userInfo }
    },

    // 刷新页面重新获取用户信息
    async uploadUserInfo (state) {
      if(state.userInfo.userName) {return}
      let userToken = localStorage.getItem('userToken')
      if (userToken) {
        state.userToken = userToken
        // 需要再次获取用户基本信息，做常态化登录，避免刷新时丢失登录信息
        try {
          let res = await getUserInfo()
          state.userInfo = { ...state.userInfo, ...res.data }
        } catch (err) {
          console.log(err)
          state.userInfo = {}
          state.userToken = ''
          localStorage.removeItem('userToken')
        }
      }
    },

    // 清除路由
    clearRouterHistory (state, name) {
      let index = state.routerHistory.findIndex(_=>{ return _.name == name })
      state.routerHistory.splice(index, 1)
    },

    // 设置历史路由
    setRouterHistory (state, value) {
      let routerHistoryName = state.routerHistory.map(_=>{ return _.name })
      let isManage = value.path.indexOf('/manage/') !== -1
      let isMenu = value.meta.isMenu
      if (!routerHistoryName.includes(value.name) && isManage && isMenu ) {
        state.routerHistory.push(value)
      }
    }
  },
  actions: {},
  modules: {}
})
export default store
