import Axios from 'axios'
import { Message, MessageBox} from 'element-ui'
import store from '@/store/index.js'
const http = Axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30 * 1000
})

http.interceptors.request.use(config => {
  let isAdmin = config.url.indexOf('adminapi/') !== -1
  if (store.state.userToken && !isAdmin) {
    config.headers['token'] = store.state.userToken
  }
  if (store.state.adminToken && isAdmin) {
    config.headers['ad-token'] = store.state.adminToken
  }
  return config
}, err => {
  console.log(err)
  Message({
    message: err.message || '发生了一点小错误！',
    type: 'error'
  })
  return Promise.reject(err)
})

http.interceptors.response.use(res => {
  if (res.status === 200 && res.data.code === 200) {
    return res.data
  } else if(res.data.code == 401){
    MessageBox.confirm('该操作需要登录，是否前往登录？', '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let isAdmin = res.config.url.indexOf('adminapi/') !== -1
      store.commit('setRouseLoginInfo', { show: true, type: 'login', userType: isAdmin ? 'admin' : 'user' })
    }).catch(() => {})
    return Promise.reject(res.data)
  } else {
    Message({
      message: res.data.msg || '发生了一点小错误！',
      type: 'error'
    })
    return Promise.reject(res.data)
  }
}, err => {
  console.log(err)
  Message({
    message: err.message || '发生了一点小错误！',
    type: 'error'
  })
  return Promise.reject(err)
})

export default http
